<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    :content-class="$vuetify.breakpoint.lgAndUp ? 'rounded-xl' : undefined"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    light
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card
      style="width: 100vw; overflow-x: hidden;"
      :class="$vuetify.breakpoint.lgAndUp ? 'rounded-xl' : undefined"
    >
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>CSV IMPORT</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="pt-0" v-if="aktivitaet">
        <v-col cols="12" class="pt-0">
          <v-row justify="center">
            <v-col cols="11" v-if="!csvfile.length > 0">
              <h3>Info:</h3>
              <p>
                <b>Baue deine CSV-Datei wie folgt auf:</b>
              </p>
              <code
                v-if="
                  publicdata.wettkampf
                    ? publicdata.wettkampf.nation_required
                    : false
                "
              >
                Nachname;Vorname;Straße;PLZ;Ort;Nation;Telefon;Email
                Nachname1;Vorname1;Straße1;PLZ1;Nation;Ort1;Telefon1;Email1
                Nachname2;Vorname2;Straße2;PLZ2;Nation;Ort2;Telefon2;Email2 usw
              </code>
              <code v-else>
                Nachname;Vorname;Straße;PLZ;Ort;Telefon;Email
                Nachname1;Vorname1;Straße1;PLZ1;Ort1;Telefon1;Email1
                Nachname2;Vorname2;Straße2;PLZ2;Ort2;Telefon2;Email2 usw
              </code>

              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-row justify="center">
                <v-col align-self="center">
                  <vue-csv-import
                    v-model="csvfile"
                    :map-fields="
                      publicdata.wettkampf
                        ? publicdata.wettkampf.nation_required
                          ? [
                              'Nachname',
                              'Vorname',
                              'Straße',
                              'PLZ',
                              'Ort',
                              'Nation',
                              'Telefon',
                              'Email',
                            ]
                          : [
                              'Nachname',
                              'Vorname',
                              'Straße',
                              'PLZ',
                              'Ort',
                              'Telefon',
                              'Email',
                            ]
                        : [
                            'Nachname',
                            'Vorname',
                            'Straße',
                            'PLZ',
                            'Ort',
                            'Telefon',
                            'Email',
                          ]
                    "
                    tableClass="table border"
                    :headers="true"
                    :autoMatchFields="true"
                    v-if="!csvfile.length > 0"
                  >
                    <template slot="error">
                      Dateityp ist ungültig
                    </template>

                    <template slot="thead">
                      <p>
                        Weise die Spalten aus deiner Datei den benötigten
                        Feldern zu
                      </p>
                      <tr>
                        <th style="border-bottom: 1px solid #000;">
                          Benötigte Felder
                        </th>
                        <th style="border-bottom: 1px solid #000;">
                          Spalten in deiner Datei
                        </th>
                      </tr>
                    </template>

                    <template slot="next" slot-scope="{ load }">
                      <v-btn
                        large
                        rounded
                        class="my-3"
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click.prevent="load"
                      >
                        DATEI LADEN
                      </v-btn>
                    </template>

                    <template slot="submit" slot-scope="{ submit }">
                      <v-btn
                        large
                        :color="template.colors.primary"
                        :style="`color: ${template.colors.inline_primary_text};`"
                        @click.prevent="submit"
                      >
                        IMPORT STARTEN
                      </v-btn>
                    </template>
                  </vue-csv-import>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="11" class="pb-0" v-if="csvfile.length > 0">
              <v-row :style="`color: ${template.colors.block_text};`">
                <v-col cols="12">
                  <h2>Import-Vorschau</h2>
                </v-col>
                <v-col cols="12">
                  <v-row class="font-weight-bold">
                    <v-col>Name</v-col>
                    <v-col>Adresse</v-col>
                    <v-col>Nation</v-col>
                    <v-col>Telefon</v-col>
                    <v-col>E-Mail</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-for="(row, index) in csvfile" :key="index">
                  <v-row>
                    <v-col>{{ row.Vorname }} {{ row.Nachname }}</v-col>
                    <v-col>{{ row.Straße }}, {{ row.PLZ }} {{ row.Ort }}</v-col>
                    <v-col
                      v-if="
                        publicdata.wettkampf
                          ? publicdata.wettkampf.nation_required
                          : false
                      "
                    >
                      {{ row.Nation }}
                    </v-col>
                    <v-col>{{ row.Telefon }}</v-col>
                    <v-col>{{ row.Email }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center pb-0" v-if="csvfile.length > 0">
              <v-btn
                rounded
                large
                :color="template.colors.primary"
                @click="startImport()"
                :loading="loading"
              >
                <v-icon class="mr-2">mdi-upload</v-icon>
                TEAM IMPORTIEREN
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-0" v-if="!aktivitaet">
        <v-col cols="8">
          <h2 class="text-center">Bitte wähle eine Aktivität aus</h2>
        </v-col>
        <v-col class="text-center" cols="8">
          <v-btn
            :color="template.colors.primary"
            :light="!template.isDark(template.colors.primary)"
            :dark="template.isDark(template.colors.primary)"
            rounded
            large
            @click="aktivitaet = 'Sportler'"
          >
            SPORTLER
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="8">
          <v-btn
            :color="template.colors.primary"
            :light="!template.isDark(template.colors.primary)"
            :dark="template.isDark(template.colors.primary)"
            rounded
            large
            @click="aktivitaet = 'Sportler'"
          >
            TRAINER
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="8">
          <v-btn
            :color="template.colors.primary"
            :light="!template.isDark(template.colors.primary)"
            :dark="template.isDark(template.colors.primary)"
            rounded
            large
            @click="aktivitaet = 'Sportler'"
          >
            KAMPFRICHTER
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="8">
          <v-btn
            :color="template.colors.primary"
            :light="!template.isDark(template.colors.primary)"
            :dark="template.isDark(template.colors.primary)"
            rounded
            large
            @click="aktivitaet = 'Sportler'"
          >
            ORGA-TEAM
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from 'firebase'
import store from '../../../store'
import { mapGetters } from 'vuex'
import { VueCsvImport } from 'vue-csv-import'

export default {
  name: 'Spieler-erstellen',
  data() {
    return {
      csvfile: [],
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
      },
      aktivitaet: '',
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
      },
      valid: false,
    }
  },
  mounted() {
    this.t.verein = this.verein.name
  },
  computed: {
    ...mapGetters({
      verein: 'verein',
      publicdata: 'publicdata',
      template: 'template',
    }),
  },
  components: { VueCsvImport },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    create() {
      if (this.$refs.form.validate()) {
        store.dispatch('addGastteammember', {
          vorname: this.t.vorname.trim(),
          name: this.t.name.trim(),
          adresse: this.t.adresse.trim(),
          plz: this.t.plz.trim(),
          ort: this.t.ort.trim(),
          telefon: this.t.telefon.trim(),
          temp: true,
        })
        this.closing()
      }
    },
    async startImport() {
      if (this.csvfile) {
        this.loading = true
        var i
        for (i = 0; i < this.csvfile.length; i++) {
          store.dispatch('addWKteammember', {
            person: {
              vorname: this.csvfile[i].Vorname.trim(),
              name: this.csvfile[i].Nachname.trim(),
              adresse: this.csvfile[i].Straße.trim(),
              ort: this.csvfile[i].Ort.trim(),
              plz: this.csvfile[i].PLZ.trim(),
              nation: this.csvfile[i].Nation
                ? this.csvfile[i].Nation.trim()
                : '',
              telefon: this.csvfile[i].Telefon.trim(),
              email: this.csvfile[i].Email.trim(),
              aktivitaet: this.aktivitaet.trim(),
            },
            disziplinen: [],
            disziplinen_ids: [],
          })
        }
        this.closing()
      }
    },
  },
}
</script>

<style>
.form-control-file {
  color: #000;
}
</style>
