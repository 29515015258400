<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    :content-class="$vuetify.breakpoint.lgAndUp ? 'rounded-xl' : undefined"
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Teammitglied hinzufügen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4">
          <v-col cols="10" class="px-2 pb-0">
            <v-text-field
              v-model="t.vorname"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Vorname"
            ></v-text-field>
            <v-text-field
              v-model="t.name"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Nachname"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.adresse"
              :rules="[rules.required, rules.street, rules.streetnr]"
              filled
              rounded
              label="Straße + Nr."
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="px-2 py-0">
            <v-text-field
              v-model="t.plz"
              :rules="[rules.required, rules.plz]"
              filled
              rounded
              label="Postleitzahl"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="px-2 py-0">
            <v-text-field
              v-model="t.ort"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Ort"
            ></v-text-field>
          </v-col>
          <v-col
            cols="11"
            class="px-2 py-0"
            v-if="publicdata.wettkampf.nation_required"
          >
            <v-text-field
              v-model="t.nation"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Nation"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.telefon"
              :rules="[rules.required, rules.telefon]"
              filled
              rounded
              label="Telefon-/Mobilnummer"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.email"
              :rules="[rules.required, rules.email]"
              filled
              rounded
              label="E-Mail"
            ></v-text-field>
          </v-col>
          <v-col
            cols="10"
            class="px-2 py-0"
            v-if="publicdata.wettkampf.disziplinen.length > 0"
          >
            <v-select
              filled
              rounded
              multiple
              label="Disziplinen"
              :items="publicdata.wettkampf.disziplinen"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
              v-model="disziplinen_ids"
            ></v-select>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-select
              filled
              rounded
              label="Aktivität"
              :items="aktivitaeten"
              :rules="[rules.required]"
              v-model="t.aktivitaet"
            ></v-select>
          </v-col>
          <v-col
            cols="10"
            class="my-5"
            v-if="testpflicht_required || gg_regel || ggplus_regel"
          >
            <v-checkbox
              v-if="gg_regel"
              v-model="ggg.geimpft"
              @change="
                ggg.getestet = false
                ggg.genesen = false
              "
            >
              <template slot="label">
                <span>
                  Hat
                  <b>&nbsp;vollständige Impfung&nbsp;</b>
                  gegen COVID-19.
                </span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="gg_regel"
              v-model="ggg.genesen"
              @change="
                ggg.geimpft = false
                ggg.getestet = false
              "
            >
              <template slot="label">
                <span>
                  Ist
                  <b>&nbsp;vollständig genesen&nbsp;</b>
                  von COVID-19 (Nicht älter als 6 Monate).
                </span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="10" class="px-2">
            <v-btn
              class="my-4"
              rounded
              block
              large
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              @click="create()"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Hinzufügen
            </v-btn>
            <v-btn
              class="my-4"
              rounded
              block
              large
              outlined
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              @click="closing()"
            >
              Abbrechen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from 'firebase'
import store from '../../../store'
import { mapGetters } from 'vuex'

export default {
  name: 'Spieler-erstellen',
  data() {
    return {
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        nation: '',
        telefon: '',
        email: '',
        aktivitaet: '',
      },
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      disziplinen: [],
      disziplinen_ids: [],
      valid: false,
      aktivitaeten: ['Sportler', 'Trainer', 'Kampfrichter', 'Orga-Team'],
    }
  },
  mounted() {
    this.t.verein = this.verein.name
    console.log(this.publicdata.wettkampf.disziplinen)
  },
  computed: {
    ...mapGetters({
      verein: 'verein',
      publicdata: 'publicdata',
      template: 'template',
    }),
    testpflicht_required() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (tthis.publicdata.verein.hygienekonzept.testpflicht) {
          if (!this.publicdata.verein.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    create() {
      this.disziplinen = []
      this.disziplinen_ids.forEach((d) => {
        this.publicdata.wettkampf.disziplinen.forEach((di) => {
          if (d == di.id) {
            this.disziplinen.push(di)
          }
        })
      })
      if (this.$refs.form.validate()) {
        store.dispatch('addWKteammember', {
          person: {
            vorname: this.t.vorname.trim(),
            name: this.t.name.trim(),
            adresse: this.t.adresse.trim(),
            plz: this.t.plz.trim(),
            ort: this.t.ort.trim(),
            telefon: this.t.telefon.trim(),
            nation: this.t.nation ? this.t.nation.trim() : '',
            email: this.t.email.trim(),
            aktivitaet: this.t.aktivitaet.trim(),
          },
          geimpft: this.ggg.geimpft,
          genesen: this.ggg.genesen,
          disziplinen: this.disziplinen,
          disziplinen_ids: this.disziplinen_ids,
        })
        this.closing()
      }
    },
  },
}
</script>
