<template>
  <div>
    <v-row justify="center" v-if="!publicdata.loading">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto" class="text-center" align-self="center">
            <v-img width="30px" :src="publicdata.verein.verein.logo" />
          </v-col>
          <v-col cols="auto" align-self="center">
            <small class="white--text">
              {{ publicdata.verein.verein.name }}
            </small>
            <br />
            <b>{{ publicdata.wettkampf.name }}</b>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row justify="center" v-if="!finished">
          <v-col cols="auto" align-self="center">
            <v-btn icon exact :to="`/wk/flvw30test/${publicdata.wettkampf.id}`">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-row justify="center">
              <v-col cols="auto" class="text-center mb-0" align-self="center">
                <v-icon large>
                  mdi-account-group
                </v-icon>
              </v-col>
              <v-col cols="auto" align-self="center">
                <h3>
                  Teamanmeldung
                </h3>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'"
            align-self="center"
            v-if="gastteam.length > 0 || $vuetify.breakpoint.mdAndUp"
          >
            <v-btn
              :color="template.colors.inline_primary_text"
              :light="!template.isDark(template.colors.inline_primary_text)"
              :dark="template.isDark(template.colors.inline_primary_text)"
              class="my-5"
              large
              rounded
              :disabled="gastteam.length == 0 || !accept_privacy"
              :loading="saving"
              @click="save_team()"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              ANMELDUNG ABSCHLIESSEN
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          justify="center"
          class="mt-4"
          v-if="publicdata && !blocked && !error"
        >
          <v-col cols="12" v-if="step == 1 && !finished">
            <v-row justify="center">
              <v-col cols="11" class="text-center">
                <h3 class="my-4 white--text" v-if="gastteam.length == 0">
                  Du hast noch keine Personen hinzugefügt.
                </h3>
                <v-card
                  v-for="(m, index) in gastteam"
                  :key="index"
                  class="my-1 rounded-xl"
                  :color="delete_member_id == m.id ? 'error' : 'success'"
                  @click="
                    if (delete_member_id == m.id) {
                      delete_member = m
                      delete_tmember = true
                    } else {
                      delete_member_id = m.id
                    }
                  "
                >
                  <v-card-title>
                    <v-icon class="mr-4" v-if="delete_member_id != m.id">
                      mdi-check-bold
                    </v-icon>
                    {{ m.person.vorname }} {{ m.person.name }}
                    <span class="ml-3 font-weight-light">
                      ({{ m.person.aktivitaet }})
                    </span>
                    {{ delete_member_id == m.id ? ' löschen?' : '' }}
                  </v-card-title>
                </v-card>
                <v-dialog persistent v-model="delete_tmember" max-width="290">
                  <v-card>
                    <v-card-title class="headline font-weight-light">
                      Willst du&nbsp;
                      <div class="ml-2 font-weight-bold" v-if="delete_member">
                        {{ delete_member.person.vorname }}
                        {{ delete_member.person.name }}
                      </div>
                      wirklich löschen?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        @click="
                          delete_tmember = false
                          delete_member_id = ''
                        "
                      >
                        Abbrechen
                      </v-btn>
                      <v-btn
                        color="success"
                        outlined
                        @click="del_member(delete_member)"
                      >
                        Bestätigen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="10" class="mt-5 text-center">
                <v-btn outlined block rounded large @click="newmember = true">
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  PERSON HINZUFÜGEN
                </v-btn>
                <CreateMember
                  v-if="newmember"
                  v-on:closing="newmember = false"
                />
              </v-col>
              <v-col cols="10" class="text-center">
                <v-btn
                  rounded
                  block
                  large
                  @click="csvmember = true"
                  :color="template.colors.secondary"
                  :style="`color: ${template.colors.inline_secondary_text};`"
                >
                  <v-icon class="mr-1">mdi-database-import</v-icon>
                  CSV IMPORT
                </v-btn>
                <TeamCSVImport
                  v-if="csvmember"
                  v-on:closing="csvmember = false"
                />
              </v-col>
              <v-col cols="10">
                <span>
                  <b>
                    Einverständniserklärung zur Erhebung personenbezogener Daten
                  </b>
                  <br />
                  <br />

                  Der Verein ist im Rahmen der Coronaschutzverordnung
                  (CoronaSchVO) des Landes Nordrhein-Westfalens (NRW) während
                  der Corona-Pandemie zur Erhebung von personenbezogenen
                  Kontaktdaten und Aufenthaltszeiträumen verpflichtet. Die
                  Erhebung der Daten erfolgt gemäß § 4a Abs. 1 i.V.m. Abs. 2
                  CoronaSchVO NRW in der derzeit gültigen Fassung.
                </span>
                <v-checkbox v-model="accept_privacy">
                  <template slot="label">
                    <span>
                      Ich bestätige, dass ich von dem Inhaber der eingegeben
                      Daten zur Speicherung der Daten autorisiert wurde und
                      diesem die
                      <a
                        :href="
                          template.datenschutz
                            ? template.datenschutz.link
                            : '/datenschutz'
                        "
                      >
                        Datenschutzinformation
                      </a>
                      zur Kenntnis gesetzt habe.
                    </span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" v-if="finished">
            <h2 class="text-center">Team erfolgreich erfasst!</h2>
            <h4 class="text-center">
              Vielen Dank für die Erfassung deines Teams für den Wettkampf
              {{ publicdata.wettkampf.name }}
            </h4>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="mt-4"
          v-if="!publicdata && !blocked && !login && !error"
        >
          <v-col cols="12" class="text-center mb-4">
            <h2>Verein wird geladen</h2>
            <v-progress-circular
              :color="template.colors.primary"
              circle
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-4" v-if="blocked">
          <v-col cols="12" class="text-center mb-4">
            <h2>Da ist wohl etwas schief gelaufen...</h2>
            <p>
              Dein eingegebener Link scheint fehlerhaft zu sein. Bitte überprüfe
              deine Eingabe und versuche es erneut.
            </p>
          </v-col>
        </v-row>
        <Login v-if="zuschauerlogin" v-on:closing="zuschauerlogin = false" />
        <CreateMember v-if="newmember" v-on:closing="newmember = false" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
//import firebase, { firestore } from 'firebase'
import router from '../../../routes/index'
import Login from '../login.vue'
import CreateMember from './createMember'
import TeamCSVImport from './csvimport'

export default {
  name: 'Gastteam-Erfassung',
  data() {
    return {
      tn_gast: [],
      teams: [],
      newzuschauer: false,
      zuschauerlogin: false,
      loading: false,
      gastparam: '',
      accept_privacy: false,
      gleicherverein: false,
      blocked: false,
      checkpin: true,
      login: false,
      error: false,
      manuell: false,
      errortext: '',
      pin: '',
      pinfalse: false,
      newmember: false,
      require_login: false,
      step: 1,
      team: '',
      teammember: [],
      tempmember: [],
      auswahl: [],
      finished: false,
      saving: false,
      csvmember: false,
      delete_member: '',
      delete_member_id: '',
      delete_tmember: false,
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      verein: 'verein',
      gastteam: 'wettkampfteam',
      template: 'template',
      user: 'user',
    }),
  },
  components: {
    Login,
    CreateMember,
    TeamCSVImport,
  },
  mounted() {
    if (!this.user) {
      this.zuschauerdaten = ''
      if (this.$route.params.sportstaette) {
        router.push(
          '/' +
            this.$route.params.verein +
            '/' +
            this.$route.params.sportstaette,
        )
      } else {
        router.push('/' + this.$route.params.verein)
      }
    }
    console.log(this.user)
    if (this.user.data) {
      this.login = true
      console.log('Eingeloggt')
      if (this.user.data.teams) {
        this.teams = this.user.data.user
      } else if (this.user.data.uid) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Team')
          .get()
          .then((snap) => {
            this.teams = []
            snap.forEach((doc) => {
              this.teams.push(doc.id)
            })
          })
      }
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    save_to_team() {
      if (this.team) {
        this.loading = true
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .add({
            art: 'Auswärtsspiel',
            beginn: this.veranstaltung.beginn,
            ende: this.veranstaltung.ende,
            gastmannschaft: this.veranstaltung.mannschaft,
            mannschaft: this.team,
            maxzuschauer: this.veranstaltung.maxzuschauer,
            sportstaette: this.veranstaltung.sportstaette,
            gastgeberverein: {
              id: this.verein.id,
              name: this.verein.name,
              logo: this.verein.logo,
              veranstaltung: this.veranstaltung.id,
            },
          })
          .then((docRef) => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.verein.id)
              .collection('Veranstaltung')
              .doc(this.veranstaltung.id)
              .update({
                gastmannschaft: this.user.data.verein.name,
                gastverein: {
                  id: this.user.data.uid,
                  name: this.user.data.verein.name,
                  logo: this.user.data.verein.logo,
                  team: this.team,
                  veranstaltung: docRef.id,
                },
              })
              .then(() => {
                router.push('/veranstaltung/' + docRef.id)
              })
          })
      }
    },
    check_pin() {
      if (this.veranstaltung.gastteam.pin == this.pin) {
        this.step = 2
      } else {
        this.pinfalse = true
        setTimeout(() => {
          this.pinfalse = false
        }, 5000)
      }
    },
    abwesend(index) {
      var i
      for (i = 0; i < this.auswahl.length; i++) {
        if (this.teammember[index].id == this.auswahl[i].id) {
          this.auswahl.splice(i, 1)
          this.refresh_team()
        }
      }
    },
    anwesend(index) {
      this.auswahl.push(this.teammember[index])
      this.refresh_team()
    },
    refresh_team() {
      var member = this.teammember
      this.teammember = []
      var i, j, index
      for (i = 0; i < member.length; i++) {
        this.teammember.push(member[i])
        index = this.teammember.length - 1
        this.teammember[index].anwesend = false
        for (j = 0; j < this.auswahl.length; j++) {
          if (this.auswahl[j].id) {
            if (this.auswahl[j].id == this.teammember[index].id) {
              this.teammember[index].anwesend = true
            }
          }
        }
      }
    },
    load_gast_team() {
      var i, j, find
      for (i = 0; i < this.gastteam.length; i++) {
        find = false
        for (j = 0; j < this.teammember.length; j++) {
          if (this.gastteam[i].id) {
            if (this.gastteam[i].id == this.teammember[j].id) {
              find = true
            }
          }
          if (!find) {
            this.tempmember.push(this.gastteam[i])
          }
        }
      }
    },
    load_team(team) {
      this.team = team
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.team)
        .collection('Mitglieder')
        .get()
        .then((snap) => {
          this.teammember = []
          this.auswahl = []
          snap.forEach((doc) => {
            this.teammember.push(doc.data())
            var i, index
            index = this.teammember.length - 1
            this.teammember[index].id = doc.id
            for (i = 0; i < this.gastteam.length; i++) {
              if (this.gastteam[i].id) {
                if (this.gastteam[i].id == this.teammember[index].id) {
                  this.teammember[index].anwesend = true
                  this.auswahl.push(this.teammember[index])
                }
              }
            }
          })
        })
    },
    async save_team() {
      this.saving = true
      var i
      this.gastteam.forEach((team) => {
        console.log(team)
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Veranstaltung')
          .doc(this.publicdata.wettkampf.id)
          .collection('Anmeldung')
          .add(team)
          .then((docRef) => {
            firebase
              .firestore()
              .collection('email')
              .add({
                appname: this.template.var.verband,
                template: 'WETTKAMPF_ANMELDUNG',
                to: team.person.email,
                name: team.person.vorname + ' ' + team.person.nachname,
                vorname: team.person.vorname,
                verein: this.publicdata.verein.verein.name,
                wk_name: this.publicdata.wettkampf.name,
                wk_id: this.publicdata.wettkampf.id,
                wk_anmeldung: docRef.id,
                wk_beginn:
                  this.parseDate(this.publicdata.wettkampf.beginn.datum) +
                  ' um ' +
                  this.publicdata.wettkampf.beginn.uhrzeit +
                  ' Uhr',
                domain: this.template.var.app_url,
                alias: this.$route.params.verein,
              })
              .then((mailRef) => {
                this.loading = false
                this.finished = true
              })
          })
          .catch((error) => {
            console.log(error)
          })
      })
      this.saving = false
      this.finished = true
    },
    del_member(member) {
      store.dispatch('removeWKteammember', member)
      this.delete_tmember = false
      ;(this.delete_member = ''), (this.delete_member_id = '')
    },
    anonym() {
      this.loading = true
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInAnonymously()
        })
        .then(() => {
          this.loading = false
          this.manuell = true
          store.dispatch('loadGastteam')
        })
        .catch(function (error) {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
>
