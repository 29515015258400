<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Login</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" style="min-height: 50vh;">
        <v-col cols="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row justify="center" class="mt-5">
              <v-col cols="11" md="8" class="py-0">
                <v-text-field
                  filled
                  label="E-Mail Adresse"
                  :rules="[rules.required, rules.email]"
                  v-model="v.email"
                ></v-text-field>
              </v-col>
              <v-col cols="11" md="8" class="py-0">
                <v-text-field
                  filled
                  type="password"
                  label="Passwort"
                  :rules="[rules.required, rules.password]"
                  v-model="v.password"
                ></v-text-field>
              </v-col>
              <v-col cols="11" class="py-0 text-center my-3">
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  class="my-5"
                  :block="$vuetify.breakpoint.smAndDown"
                  large
                  @click="login()"
                  :loading="loading"
                >
                  Login
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="10" v-if="!art" align-self="center">
          <v-row justify="center">
            <v-col align-self="center"><v-divider></v-divider></v-col>
            <v-col cols="auto" align-self="center"><h3>ODER</h3></v-col>
            <v-col align-self="center"><v-divider></v-divider></v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" v-if="!art" align-self="center">
          <v-card
            color="#4285F4"
            dark
            link
            class="my-5 pa-0"
            @click="g_signup()"
          >
            <v-row class="pa-0">
              <v-col cols="auto" class="py-0 mr-0 pr-0">
                <v-img src="/img/google-login.png" width="50" />
              </v-col>
              <v-col align-self="center">
                <h3>Mit Google anmelden</h3>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            color="#3b5998"
            link
            class="my-5 pa-0"
            @click="fb_signup()"
            v-if="false"
          >
            <v-row justify="center" class="pa-0 pr-2">
              <v-col cols="12" align-self="center" class="py-2">
                <h3>
                  <v-icon class="mx-2" large>mdi-facebook</v-icon>
                  Mit Facebook anmelden
                </h3>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            color="#00acee"
            link
            class="my-5 pa-0"
            @click="twitter_signup()"
          >
            <v-row justify="center" class="pa-0 pr-2">
              <v-col cols="12" align-self="center" class="py-2">
                <h3>
                  <v-icon class="mx-2" large>mdi-twitter</v-icon>
                  Mit Twitter anmelden
                </h3>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar
      :color="template.colors.primary"
      :dark="!template.light"
      bottom
      v-model="error"
    >
      {{ errortext }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
//import firebase from 'firebase'

export default {
  name: 'Mein-Verein',
  data() {
    return {
      art: '',
      v: {
        vorname: '',
        nachname: '',
        anschrift: '',
        plz: '',
        ort: '',
        telefon: '',
        email: '',
        password: '',
        passwordconfirm: '',
      },
      valid: false,
      error: false,
      errortext: '',
      loading: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        password: (v) =>
          v.length >= 6
            ? true
            : 'Dein Passwort sollte mindestens 6 Zeichen lang sein',
        passwordconfirm: (v) =>
          v == this.v.password ? true : 'Die Passwörter stimmen nicht überein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      template: 'template',
    }),
  },
  mounted() {
    if (!this.publicdata.verein) {
      store.dispatch('setPublic', {
        alias: this.$route.params.verein,
        sportstaette: this.$route.params.sportstaette,
      })
    } else {
      if (this.publicdata.verein.alias != this.$route.params.verein) {
        store.dispatch('setPublic', {
          alias: this.$route.params.verein,
          sportstaette: this.$route.params.sportstaette,
        })
      }
    }
  },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    g_signup() {
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          // The email of the user's account used.
          var email = error.email
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential
          // ...
        })
    },
    fb_signup() {
      var provider = new firebase.auth.FacebookAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          // The email of the user's account used.
          var email = error.email
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential
          // ...
          console.log(error)
        })
    },
    twitter_signup() {
      var provider = new firebase.auth.TwitterAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken
          // The signed-in user info.
          var user = result.user
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          this.error = error
          this.errortext = error.message
          console.log(error)
        })
    },
    login() {
      if (this.$refs.form.validate()) {
        this.v.email = this.v.email.replace(' ', '')
        this.loading = true
        firebase
          .auth()
          .signInWithEmailAndPassword(this.v.email, this.v.password)
          .catch((error) => {
            console.log(error)
            this.loading = false
            this.error = error
            this.errortext = error.message
          })
      }
    },
  },
}
</script>
>
